import _get from "lodash/get";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import api from "@/api";
import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import { redirectToProperErrorPage } from "@/helpers/router";

export default function({ next, to, router }) {
  api
    .get("/projects/create")
    .then(response => {
      httpSuccessResponseLogger(response);

      const project = _get(response, "data.result", {});

      if (!_isNil(project.project_creation_progress)) {
        const details = _get(project.project_creation_progress, "details", {});
        details.full_project_addr = project.address.long_address;

        if (!_isEmpty(details)) {
          store.commit("createProject/SET_PROJECT_CREATION_PROGRESS", details);
        }
      }

      if (!_isNil(project.engineering_values)) {
        store.commit(
          "createProject/SET_ENGINEERING_VALUES",
          project.engineering_values
        );
      }

      const id = _get(project, "id", null);

      store.commit("createProject/SET_ID", id);

      const activity_id = _get(project, "recent_activity.id", null);

      store.commit("createProject/SET_ACTIVITY_ID", activity_id);

      store.commit(
        "createProject/SET_SHIPPING_PROFILE_ID",
        project.creator.shipping_profile_id
      );

      if (project.shipping_profile ?? null) {
        store.commit(
          "createProject/SET_FORMDATA_TYPE_SHIPPING_PROFILE",
          project.shipping_profile
        );
      }

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      redirectToProperErrorPage(error, to, router);
    });
}
